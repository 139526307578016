<template>
  <main>
    <header class="app-arrow-header text-center">
      <a href="#" class="app-arrow-header-link left" @click="$router.go(-1);">
        <i class="fas fa-chevron-left"></i>
      </a>
      <div class="onboard-text-medium text-dark">{{fname}}, co Cię interesuje?</div>
      <div class="onboard-text text-dark">wybierz kręgi tematyczne</div>
    </header>
    <div class="app-content-box bg-gray">
      <div v-if="!isLoading">
        <div class="growing-cover-slider swiper-container-book wrapper">
          <div class="swiper-wrapper">
            <OnBoardingSelectGenres :props="genres" id='0' :parent="addParent" />
          </div>
          <div class="bg-gray wrapper-tags pb-5">
            <h4 class="slider-book-title">{{label}}</h4>
            <div class="container tags-box">
              <ul>
                <li v-for="(tag, key) in bookData" :key="key"
                    @click="deleteCat(tag.id, key)"
                    :class="{active: genresInPlan.filter(function(item) { return item === tag.id}).length > 0}"
                >
                  <span :class="{dark: genresInPlan.filter(function(item) { return item === tag.id}).length > 0}">{{ tag.label }} <i class="fas fa-times"></i></span>
                </li>
              </ul>
              <button class="btn-black-full w-100 mt-3 mb-5"  @click="submit">Zapisz</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <Loader />
      </div>
      <ModalSlot title="Dodaj książkę" v-if="isPopupShown === true" @close-modal="closeModal">
        <div class="text-center mb-4 modal-main-title">Musisz wybrać co najmiej dwa gatunki</div>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
      </ModalSlot>
    </div>
  </main>

</template>
<script>
import OnBoardingSelectGenres from '../components/OnBoardingSelectGenres'
import Loader from '../components/Loader'
import EventBus from '../eventBus'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'OnboardingChoose',
  props: {
    name: String
  },
  components: {
    OnBoardingSelectGenres,
    Loader,
    ModalSlot
  },
  data () {
    return {
      activeTab: 'books',
      fname: '',
      isLoading: true,
      tabs: ['books'],
      bookData: {},
      list: [],
      label: '',
      genres: [],
      genresInPlan: [],
      subcats: [],
      parent: 0,
      addParent: 0,
      isPopupShown: false
    }
  },
  created () {
    if (this.getOnboardingFinished()) {
      this.$router.push('/')
    } else {
      this.$https('/profile', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(response => {
          this.fname = response.data.fname
        })
      this.$https('/genres', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.genres = response.data.response
        this.isLoading = false
      })
        .catch(error => {
          console.log('', error)
        })

      EventBus.$on('selected-books-genres', (payload) => {
        this.updateData(payload)
      })
      EventBus.$on('selected-genres', (payload) => {
        this.selectedGenres(payload)
      })
    }
  },
  methods: {
    submit () {
      const selected = this.genresInPlan.join(',')
      if (this.genresInPlan.length < 2) {
        this.isLoading = false
        this.isPopupShown = true
      } else {
        const getData = `genres=${selected}`
        this.$https('/genres', {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: getData
        }).then(response => {
          this.setOnboardingFinished(true)
          this.$router.push('/')
        })
          .catch(error => {
            console.log('', error)
          })
      }
    },
    selectedGenres (payload) {
      this.genresInPlan = payload.map(e => e.id)
      // payload.forEach(e => {
      //   e.children.forEach(e => this.genresInPlan.push(e.id))
      // })
    },
    updateData (payload) {
      this.bookData = payload.categories
      this.label = payload.label
      this.parent = payload.parent
    },
    deleteCat (tag, key) {
      const index = this.genresInPlan.indexOf(tag)
      if (index > -1) {
        this.genresInPlan.splice(index, 1)
      } else {
        this.genresInPlan.push(tag)
        if (this.genresInPlan.indexOf(this.parent) === -1) {
          this.genresInPlan.push(this.parent)
          this.addParent = {
            id: this.parent,
            children: {
              id: tag
            },
            select: true
          }
        }
      }
    },
    closeModal () {
      this.isPopupShown = false
    }
  },
  beforeDestroy () {
    EventBus.$off('selected-books-genres', (payload) => {
      this.updateData(payload)
    })
    EventBus.$off('selected-genres', (payload) => {
      this.selectedGenres(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .onboard-text {
    font-size: 14px;
  font-weight: 500;
    &-medium {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .tags-box {
  position: relative;
  margin-top: 15px;
  height: 100%;

  ul {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 7px;
      padding-right: 7px;
      transition: border-color .2s ease, color .2s ease;
      display: inline-block;
      border: 1px solid #ccc;
      margin: 4px 10px 4px 0;

      span {
        display: block;
        font-size: 12px;
        line-height: 14px;
        color: #a6a6a6;
      }
    }
  }

.active{
  border-color: #111;
}
.dark {
  color: #222;
}
}
</style>
